import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppButtonNext,
  AppContainer,
  AppSpinner,
  getErrorMessages,
} from '@codeer/handypark-web-common';
import { PARKING_CARD_ORIGIN_OPTIONS, ParkingCardOriginRadioButtons } from '@core';

import {
  PARKING_CARD_COUNTRY_CODE_STEPS,
  parkingCardCountryCodeAtom,
  ParkingCardCountryCodeBelgianSignInButtons,
  parkingCardCountryCodeStepAtom,
} from '@features/parking-card-country-code';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PARKING_CARD_COUNTRY_CODE_FORM_KEYS, TParkingCountryCodeForm, validationSchema } from './';

type TParkingCardCountryCodeFormProps = {
  setFormErrors: (errors: string[]) => void;
};

export const ParkingCardCountryCodeForm = ({ setFormErrors }: TParkingCardCountryCodeFormProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, {
    keyPrefix: 'radio-buttons.parking-card-origin.options',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setParkingCardCountryCodeStep = useSetAtom(parkingCardCountryCodeStepAtom);

  const [parkingCardCountryCode, setParkingCardCountryCode] = useAtom(parkingCardCountryCodeAtom);

  const defaultValues = {
    [PARKING_CARD_COUNTRY_CODE_FORM_KEYS.PARKING_CARD_COUNTRY_CODE]:
      parkingCardCountryCode === PARKING_CARD_ORIGIN_OPTIONS.BELGIAN ||
      parkingCardCountryCode === PARKING_CARD_ORIGIN_OPTIONS.OTHER
        ? parkingCardCountryCode
        : PARKING_CARD_ORIGIN_OPTIONS.OTHER,
  };

  const { handleSubmit, control, formState, watch } = useForm<TParkingCountryCodeForm>({
    resolver: yupResolver(validationSchema()),
    mode: 'onSubmit',
    values: defaultValues,
    defaultValues,
  });
  const parkingCardOriginWatcher = watch(
    PARKING_CARD_COUNTRY_CODE_FORM_KEYS.PARKING_CARD_COUNTRY_CODE,
  );

  useEffect(() => {
    if (!formState.isDirty) {
      return;
    }
    setParkingCardCountryCode(parkingCardOriginWatcher);
  }, [formState.isDirty, parkingCardOriginWatcher, setParkingCardCountryCode]);

  const resetFormErrors = () => {
    setFormErrors([]);
  };

  const onSubmitForeign: SubmitHandler<TParkingCountryCodeForm> = () => {
    resetFormErrors();
    setParkingCardCountryCodeStep(PARKING_CARD_COUNTRY_CODE_STEPS.PARKING_CARD_FOREIGN);
  };

  const onError: SubmitErrorHandler<TParkingCountryCodeForm> = data => {
    console.log('onError', data);
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
  };

  if (isLoading) {
    return (
      <AppContainer my={4} aria-busy={true}>
        <AppSpinner showText={true} label={t('BE.redirect-label')} />
      </AppContainer>
    );
  }

  return (
    <>
      <AppContainer my={4}>
        <ParkingCardOriginRadioButtons
          name={PARKING_CARD_COUNTRY_CODE_FORM_KEYS.PARKING_CARD_COUNTRY_CODE}
          control={control}
          showErrorMessage={false}
        />
      </AppContainer>

      <AppContainer>
        {parkingCardOriginWatcher === PARKING_CARD_ORIGIN_OPTIONS.BELGIAN && (
          <ParkingCardCountryCodeBelgianSignInButtons setIsLoading={setIsLoading} />
        )}
        {parkingCardOriginWatcher === PARKING_CARD_ORIGIN_OPTIONS.OTHER && (
          <AppButtonNext mb={4} onClick={handleSubmit(onSubmitForeign, onError)} />
        )}
      </AppContainer>
    </>
  );
};
