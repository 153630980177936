import { IRouteConfig } from '@codeer/handypark-web-common';
import { lazy } from 'react';

const ParkingCardCountryCodePage = lazy(() =>
  import('@features/parking-card-country-code/pages').then(module => ({
    default: module.ParkingCardCountryCodePage,
  })),
);

export const parkingCardCountryCodeRoutes: () => IRouteConfig[] = () => [
  {
    path: '/',
    component: ParkingCardCountryCodePage,
  },
  {
    path: `/*`,
    redirect: '/',
  },
];
