import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  AppDataState,
  AppSearchInput,
  getErrorMessages,
  sortAlphabetical,
  useGetCountries,
  windowScrollTop,
} from '@codeer/handypark-web-common';
import { PARKING_CARD_ORIGIN_OPTIONS } from '@core';
import {
  parkingCardCountryCodeAtom,
  TCountryOptionWithAriaLabel,
} from '@features/parking-card-country-code';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAtom } from 'jotai';
import { useMemo, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Countries,
  PARKING_CARD_FOREIGN_COUNTRY_CODE_FORM_KEYS,
  parkingCardForeignCountryCodeValidationSchema,
  TParkingCardForeignCountryCodeForm,
} from './';

type TParkingCardForeignCountryCodeFormProps = {
  setFormErrors: (errors: string[]) => void;
};

export const ParkingCardForeignCountryCodeForm = ({
  setFormErrors,
}: TParkingCardForeignCountryCodeFormProps) => {
  const { t: tCountries } = useTranslation(T_NAMESPACES.COUNTRIES);

  const [searchQuery, setSearchQuery] = useState<string>('');

  const { data: countries, dataState: countriesDataState } = useGetCountries();
  const countryOptions: TCountryOptionWithAriaLabel[] = useMemo(() => {
    const labels = (countries?.countries || [])
      .filter(country => {
        return country.iso2Code !== PARKING_CARD_ORIGIN_OPTIONS.BELGIAN;
      })
      .map(country => {
        return {
          ...country,
          value: country.iso2Code,
          label: tCountries(country.iso2Code),
          ariaLabel: tCountries(country.iso2Code),
        };
      });

    return sortAlphabetical(labels, 'label');
  }, [countries, tCountries]);

  const [parkingCardCountryCode, setParkingCardCountryCode] = useAtom(parkingCardCountryCodeAtom);

  const defaultValues = {
    [PARKING_CARD_FOREIGN_COUNTRY_CODE_FORM_KEYS.COUNTRY_CODE]: parkingCardCountryCode,
  };

  const { handleSubmit, control } = useForm<TParkingCardForeignCountryCodeForm>({
    resolver: yupResolver(parkingCardForeignCountryCodeValidationSchema()),
    mode: 'onSubmit',
    values: defaultValues,
    defaultValues,
  });

  const resetFormErrors = () => {
    setFormErrors([]);
  };

  const onSubmit: SubmitHandler<TParkingCardForeignCountryCodeForm> = data => {
    resetFormErrors();
    setParkingCardCountryCode(data[PARKING_CARD_FOREIGN_COUNTRY_CODE_FORM_KEYS.COUNTRY_CODE]);
  };

  const onError: SubmitErrorHandler<TParkingCardForeignCountryCodeForm> = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
    windowScrollTop();
  };

  return (
    <AppDataState dataState={countriesDataState} fullHeightCentered>
      <AppContainer mb={8}>
        <AppSearchInput
          id={'country'}
          value={searchQuery}
          onChange={(value: string) => setSearchQuery(value)}
        />
      </AppContainer>

      <Countries
        data={countryOptions}
        query={searchQuery}
        name={PARKING_CARD_FOREIGN_COUNTRY_CODE_FORM_KEYS.COUNTRY_CODE}
        control={control}
        onHandleSubmit={handleSubmit(onSubmit, onError)}
      />
    </AppDataState>
  );
};
