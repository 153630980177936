import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppFormErrors,
  isInfoModalOpenAtom,
  PageHeaderActions,
} from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_STEPS,
  parkingCardCountryCodeStepAtom,
} from '@features/parking-card-country-code';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ParkingCardCountryCodeForeignInfoModal, ParkingCardForeignCountryCodeForm } from './';

export const ParkingCardCountryCodeForeign = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code.foreign',
  });

  const setParkingCardCountryCodeStep = useSetAtom(parkingCardCountryCodeStepAtom);
  const setInfoModalAtom = useSetAtom(isInfoModalOpenAtom);

  const [formErrors, setFormErrors] = useState<string[]>([]);

  return (
    <>
      <PageHeaderActions
        title={t('title')}
        onPreviousHandler={() =>
          setParkingCardCountryCodeStep(PARKING_CARD_COUNTRY_CODE_STEPS.PARKING_CARD_ORIGIN)
        }
        onInfoHandler={() => setInfoModalAtom(true)}
      />
      <ParkingCardCountryCodeForeignInfoModal />
      <AppFormErrors formErrors={formErrors} mb={8} />

      <ParkingCardForeignCountryCodeForm setFormErrors={setFormErrors} />
    </>
  );
};
