import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { AppModalInfo, Paragraph } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type TParkingCardCountryCodeForeignInfoModalProps = Qa;

export const ParkingCardCountryCodeForeignInfoModal = ({
  qa = 'parking-card-foreign-country-code-info-modal',
}: TParkingCardCountryCodeForeignInfoModalProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code.foreign.info',
  });

  return (
    <AppModalInfo qa={qa} title={t('title')}>
      <Paragraph>{t('description')}</Paragraph>
    </AppModalInfo>
  );
};
