import { T_NAMESPACES } from '@codeer/handypark-common';
import { useDocumentTitle } from '@codeer/handypark-web-common';
import { ParkingCardCountryCodeStepSwitchComponent } from '@features/parking-card-country-code';
import { useTranslation } from 'react-i18next';

export const ParkingCardCountryCodePage = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES);
  useDocumentTitle(t('parking-card-country-code.document-title'));

  return <ParkingCardCountryCodeStepSwitchComponent />;
};
