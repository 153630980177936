import {
  CountriesFilteredNoResults,
  CountriesFilteredResults,
  CountriesNotFiltered,
  PARKING_CARD_FOREIGN_COUNTRY_CODE_FORM_KEYS,
  TCountryOptionWithAriaLabel,
  TParkingCardForeignCountryCodeForm,
} from '@features/parking-card-country-code';
import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import { divideCountriesByNeighbourhood, filterCountriesByQuery } from './countries.helper';

type TCountriesRadioButtonsProps = {
  data: TCountryOptionWithAriaLabel[];
  query?: string;
  name: PARKING_CARD_FOREIGN_COUNTRY_CODE_FORM_KEYS;
  control: Control<TParkingCardForeignCountryCodeForm>;
  onHandleSubmit: () => void;
};

export type TSortedCountries = {
  neighbouring: TCountryOptionWithAriaLabel[];
  other: TCountryOptionWithAriaLabel[];
};

export const Countries = ({
  data,
  query = '',
  name,
  control,
  onHandleSubmit,
}: TCountriesRadioButtonsProps) => {
  const dividedCountries = useMemo(() => {
    return divideCountriesByNeighbourhood(data);
  }, [data]);

  const filteredCountries = useMemo(() => {
    return filterCountriesByQuery(data, query);
  }, [data, query]);

  if (!query) {
    return (
      <CountriesNotFiltered
        dividedCountries={dividedCountries}
        name={name}
        control={control}
        onHandleSubmit={onHandleSubmit}
      />
    );
  }

  if (filteredCountries.length > 0) {
    return (
      <CountriesFilteredResults
        filteredCountries={filteredCountries}
        name={name}
        control={control}
        onHandleSubmit={onHandleSubmit}
      />
    );
  }

  return <CountriesFilteredNoResults />;
};
