import { ISO_LANGUAGE_CODES } from '@codeer/handypark-common';
import { mergeDeep, TTranslation } from '@codeer/handypark-web-common';
import { Resource } from 'i18next';
import { nlBe } from './nl-BE';

export const translations: TTranslation[] = [
  {
    key: ISO_LANGUAGE_CODES.NL_BE,
    values: mergeDeep([nlBe()]) as Resource,
  },
];
